import { useLocation } from "react-router-dom";
import {
  FaDiscord,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import "./Nav.css";

const Nav = (props) => {
  const {pathname} = useLocation();
  console.log(pathname)
  return (
    <nav>
      <div className="nav-title" onClick={() => window.location.assign(window.location.origin)}>
        Shrey.Games
      </div>
      <div className="nav-buttons">
        <a
          className="discord"
          href="https://discord.gg/QPphDVfXTa"
          target="_blank"
          rel="noreferrer"
        >
          <FaDiscord size={25} />
        </a>
        <a
          className="twitter"
          href="https://twitter.com/shreygames"
          target="_blank"
          rel="noreferrer"
        >
          <FaTwitter size={25} />
        </a>
        {/* <a href="https://discord.gg/Xe7bzpBZ" target="_blank">
        rel="noreferrer"
          <FaTwitch size={25} />
        </a> */}
        <a
          className="youtube"
          href="https://www.youtube.com/@shreygames"
          target="_blank"
          rel="noreferrer"
        >
          <FaYoutube size={25} />
        </a>
      </div>
    </nav>
  )
}

export default Nav;